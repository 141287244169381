'use client';

import React, { PropsWithChildren } from 'react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';

type Props = {
  locale: string;
  messages: AbstractIntlMessages;
};

function NextIntlClientContext({ locale, messages, children }: PropsWithChildren<Props>) {
  React.useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <NextIntlClientProvider locale={locale} messages={messages} onError={() => {}}>
      {children}
    </NextIntlClientProvider>
  );
}

export default NextIntlClientContext;
